'use client'

import Bgm from '@image/home/residential-mobile.webp'
import Bg from '@image/home/residential2.webp'
import HomeLayout from '../layout'
import ResidentialContent from './content'

export default function HomeResidentialNew({
  title
}) {

  return (
    <HomeLayout image={Bg} mImage={Bgm} alt='residential' home>
      <ResidentialContent title={title}></ResidentialContent>
    </HomeLayout>
  )
}
